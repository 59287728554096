import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import Header from "../components/header";
import About from "../components/about";
import ContactUs from "../components/contactUs";
import Footer from "../components/footer";

const Strategy = ({ location }) => {
  return (
    <Fragment>
      <Helmet>
        <title>We rank you #1 - google 3 pack, GMB Local SEO</title>
        <meta
          name="description"
          content="We rank you #1 on google 3 pack. Our local SEO program offers 2-month guarantee on your GMB ranking for your target keyword. Schedule a call ..."
        />
        <link href="https://seocannabis.agency/dispensary-seo" rel="canonical" />
      </Helmet>
      <main>
        <Header pathname={location.pathname} />
        <About
          titlel1="Dispensary SEO"
          // titlel2="#1"
          // caption="Local SEO, GMB - Google 3-pack"
          titleWidth="520px"
          description="You know that your dispensary is the best in your area, so gain credibility with potential customers by ranking in the top 3 of your maps results. Forget about wasting money with paid ads. We help your business succeed by ranking organically."
          desktopImg={"localseoimg"}
          mobileImg="arrow.png"
          page="local"
        />
        <ContactUs
          // head1="Search is Everything"
          //   head3="Search is everything"
          des1="Stop losing out on customers and business because of your low search results ranking. Pull more customers and make more sales by ranking high when people search for dispensaries in your area. If your current SEO strategy isn’t getting you the success you deserve, give us a call and we’ll help you rank in the top 3 spots for local dispensaries."
          // des2=" We want you to succeed. So even if for some reason you’re not booking a call with us, feel free to enter your email below and we’ll follow up over email to evaluate your search status."
          // head2="Evaluate Your Business"
          image="home.png"
        />
        <Footer isTopborder={true} />
      </main>
      <ToastContainer />
    </Fragment>
  );
};

export default Strategy;
